import { SortOrder } from '@dealroadshow/uikit';
import FileWithUploadAndPath from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/models/FileWithUploadAndPath';

enum OrderDirection {
  asc = 'asc',
  desc = 'desc',
}

interface ICompare {
  (a: File, b: File): number,
}

export const getCompareFunction = (compareFunctions: ICompare[], order: SortOrder) => (a, b) => {
  let compare = 0;
  const tmpCompareFunctions = [...compareFunctions];
  do {
    let compareFunction = tmpCompareFunctions.shift();
    if (!compareFunction) {
      break;
    }
    compare = compareFunction(a, b);
  } while (compare === 0);

  return (order === OrderDirection.desc) ? compare * -1 : compare;
};

export const compareByName = (a: FileWithUploadAndPath, b: FileWithUploadAndPath) => {
  const nameA = a.name.toLowerCase();
  const nameB = b.name.toLowerCase();

  return nameA.localeCompare(nameB, undefined, {
    numeric: true,
  });
};

export const compareByPath = (a: FileWithUploadAndPath, b: FileWithUploadAndPath) => {
  const compare = compareByPathLength(a, b);
  if (compare !== 0) {
    return compare;
  }

  return compareByPathName(a, b);
};

const compareByPathLength = (a: FileWithUploadAndPath, b: FileWithUploadAndPath) => {
  const pathLengthA = a.path.split('/')
    .filter(Boolean).length;
  const pathLengthB = b.path.split('/')
    .filter(Boolean).length;
  return pathLengthA - pathLengthB;
};

const compareByPathName = (a: FileWithUploadAndPath, b: FileWithUploadAndPath) => {
  const nameA = a.path.toLowerCase();
  const nameB = b.path.toLowerCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};

export const compareByStatus = (
  a: FileWithUploadAndPath,
  b: FileWithUploadAndPath,
) => a.upload.getStatusCompareNumber() - b.upload.getStatusCompareNumber();
