import React from 'react';
import Button, { variantTypes } from '@/Framework/UI/Atoms/Button';
import { Input, Icon, IconType } from '@dealroadshow/uikit';
import validate from './validator';
import styles from './folderInputs.scss';

interface IProps {
  fields: any,
  fieldComponent: React.ElementType,
}

const FolderInputs = ({ fields, fieldComponent: Field }: IProps) => {
  const handleFieldRemove = (index) => {
    fields.remove(index);
  };

  const folderInputs = (
    <ul className={ styles.inputsWrp }>
      { fields.map((field, index) => {
        const key = `${ field }-${ index }`;
        return (
          <li
            key={ key }
            className={ styles.inputWrp }
            data-test={ `listInput[${ index }]` }
          >
            <Field
              name={ `${ field }.name` }
              isClearable
              placeholder={ `New folder ${ index + 1 } name` }
              validate={ validate }
              component={ Input }
              dataTest={ `${ field }NameInput` }
              autoFocus
            />
            { !!index && (
              <Button
                variant={ variantTypes.text }
                onClick={ () => handleFieldRemove(index) }
                className={ styles.removeButton }
                dataTest={ `fieldRemoveButton[${ index }]` }
              >
                <Icon
                  type={ IconType.close }
                  className={ styles.removeButtonIcon }
                />
              </Button>
            ) }
          </li>
        );
      }) }
    </ul>
  );

  return (
    <>
      { folderInputs }
      <Button
        variant={ variantTypes.text }
        className={ styles.addMoreButton }
        onClick={ () => fields.push({ name: '' }) }
        dataTest="addOneMoreButton"
      >
        <Icon
          type={ IconType.add }
          className={ styles.addMoreIcon }
        />
        Add Another Folder
      </Button>
    </>
  );
};

export default FolderInputs;
