import flatten from 'lodash/flatten';
import base from '@/Framework/url/baseUrl';
import urlJoin from '@/Framework/url/helpers/urlJoin';
import config from '@/Framework/config';
import buildUrl from '@/Framework/url/helpers/buildUrl';
import shouldUseInternalNetwork from '@/Framework/Router/Next/shouldUseInternalNetwork';

export default {
  getUrl(...uris: (string | string[])[]): string {
    if (!Array.isArray(uris)) {
      uris = [uris];
    }
    uris = flatten(uris).filter((uri) => !!uri);
    return buildUrl(config.protocol, `${ config.condorSubdomain }.${ config.globalDomain }`, config.drs.condor.base, ...uris);
  },

  getUrlPathname(...uris: (string | string[])[]): string {
    return urlJoin(config.drs.condor.base, ...uris);
  },

  getRpcUrl(): string {
    if (shouldUseInternalNetwork()) {
      return this.getInternalRpcUrl();
    }

    return `${ config.tenant.condor.api.external }/condor/rpc`;
  },

  getInternalRpcUrl(): string {
    return `${ config.tenant.condor.api.internal }/rpc`;
  },

  getOpenMarketFilesUrl(...uris: string[]): string {
    return base.getApiUrl('/open-market/files', ...uris);
  },
};
