import { RedactionStructureType } from '@/dataroom/domain/vo/redaction/RedactionStructureType';
import { RedactionEntityType, RedactionFrame } from '@/Framework/UI/Organisms/DocumentViewer/plugins/RedactionPlugin/types';

export enum RedactionAction {
  Add = 'add',
  Delete = 'delete',
}

interface IRedactionAddPayload {
  type: RedactionEntityType,
  label: string,
  entries: {
    entryNumber: number,
    frames: RedactionFrame[],
  }[],
}

interface IRedactionDeletePayload {
  redactionId: string,
}

export interface IRedactionAction {
  type: RedactionStructureType,
  action: RedactionAction,
  data: (IRedactionAddPayload | IRedactionDeletePayload)[],
}
