import React from 'react';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import { Area } from '@/dataroom/domain/vo/Area';
import toArray from '@/Framework/dataHelpers/enum/toArray';
import { Radio, RadioGroup, CheckboxInput } from '@dealroadshow/uikit';
import { getAreaTitles } from '@/dataroom/domain/areas';
import capitalize from '@/Framework/dataHelpers/string/capitalize';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import styles from './searchOptions.scss';
import { IFinalFormApi } from '@/Framework/UI/Organisms/FinalForm/interfaces';

interface IProps {
  fieldComponent: IFinalFormApi['Field'],
}

const SearchOptions = ({ fieldComponent: Field }: IProps) => {
  const { tenant } = useDataroomTenantContext();
  const { dataroom } = useDataroomContext();
  const { canUserAccessStaging } = useCurrentUserContext();

  return (
    <>
      { canUserAccessStaging && (
        <div className={ styles.groupWrp }>
          <div className={ styles.subtitle }>
            Search In
          </div>
          <Field name="searchArea">
            { (fieldProps) => (
              <RadioGroup
                { ...fieldProps.input }
                selectedValue={ fieldProps.input.value }
                dataTest="searchAreaSwitchRadioGroup"
                className={ styles.radioGroup }
                isNarrow
              >
                { toArray(Area).map((area: string) => (
                  <Radio
                    key={ area }
                    label={ getAreaTitles(tenant)[area] }
                    value={ area }
                    className={ styles.radioButton }
                    dataTest={ `searchAreaSwitch${ capitalize(area) }RadioButton` }
                  />
                  )) }
              </RadioGroup>
              ) }
          </Field>
        </div>
      ) }
      { dataroom.docSearchEnabled && (
        <div className={ styles.groupWrp }>
          <div className={ styles.subtitle }>
            Additional Search Within
          </div>
          <div>
            <Field
              component={ CheckboxInput }
              className={ styles.checkbox }
              labelClassName={ styles.label }
              type="checkbox"
              name="fileContent"
              label="File Contents"
              dataTest="fileContentCheckbox"
            />
          </div>
        </div>
      ) }
    </>
  );
};

export default SearchOptions;
