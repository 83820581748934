import React, { useEffect } from 'react';
import { Modal } from '@dealroadshow/uikit';
import Button, { variantTypes } from '@/Framework/UI/Atoms/Button';
import { useTenantContext } from '@/Framework/Tenant/TenantContext';
import DataroomTenantConfig from '@/dataroom/application/config/DataroomTenantConfig';
import useDataroomNavigation from '@/dataroom/application/useDataroomNavigation';
import { useAccessRestrictedContext } from '@/dataroom/application/AccessRestrictedContext';
import { IDataroom } from '@/dataroom/domain/vo/Dataroom';
import styles from './accessRestrictedModal.scss';

interface IProps {
  dataroomDisplayName?: string,
  fromDataroom?: boolean,
  dataroom?: IDataroom,
  isStaging?: boolean,
}

const AccessRestrictedModal = ({
  dataroomDisplayName,
  fromDataroom,
  dataroom,
  isStaging,
}: IProps) => {
  const { tenant } = useTenantContext();
  const { tenantLabel, tenantLabelSm } = DataroomTenantConfig.fromCode(tenant).options;
  const { goToRootFolder } = useDataroomNavigation();
  const { isModalVisible, dataroomName, hideAccessRestrictedModal } = useAccessRestrictedContext();

  const value = fromDataroom ? 'file or folder' : tenantLabelSm;

  useEffect(() => {
    if (fromDataroom && isModalVisible) {
      goToRootFolder(dataroom, isStaging);
    }
  }, [fromDataroom, isModalVisible]);

  const footer = (
    <Button
      title="Close"
      dataTest="accessRestrictedModalButtonClose"
      variant={ variantTypes.action }
      onClick={ hideAccessRestrictedModal }
    />
  );

  if (!isModalVisible) {
    return null;
  }

  return (
    <Modal
      isVisible
      title="Access Restricted"
      dataTest="accessRestrictedModal"
      className={ styles.modal }
      modalBodyClassName={ styles.modalBody }
      onCloseClicked={ hideAccessRestrictedModal }
      footer={ footer }
    >
      <div className={ styles.container }>
        <div className={ styles.wrapper }>
          <span className={ styles.label }>{ tenantLabel }: </span>
          <span className={ styles.name }>{ dataroomDisplayName || dataroomName }</span>
        </div>
      </div>
      <p className={ styles.description }>
        The <b>{ value }</b> you are attempting to view no longer exists or is not accessible
        with your current permissions. If you believe this is an error, please contact
        the <span>{ tenantLabelSm }</span> administrator
        that invited you to this <span>{ tenantLabelSm }</span>.
      </p>
    </Modal>
  );
};

export default AccessRestrictedModal;
