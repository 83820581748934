import React, { ChangeEvent } from 'react';
import cn from 'classnames';
import { Checkbox } from '@dealroadshow/uikit';
import styles from './checkboxField.scss';
import { IFileUploadNotificationSettingsPayload } from '@/dataroom/domain/vo/emailAlerts/FileUploadEmailAlerts';
import { IStagingFileUploadNotificationSettingsPayload } from '@/dataroom/domain/vo/emailAlerts/StagingFileUploadEmailAlerts';
import { IAccessRequestNotificationSettingsPayload } from '@/dataroom/domain/vo/emailAlerts/AccessRequestEmailAlerts';
import { IQuestionsNotificationSettingsPayload, IQuestionsRealtimeNotificationSettingsPayload } from '@/dataroom/domain/vo/emailAlerts/QuestionsEmailAlerts';

interface IProps<T, R> {
  name: (keyof T & `is${ string }`) | keyof R,
  dataTest: string,
  value: boolean,
  label: string | React.ReactNode,
  disabled?: boolean,
  onChange: (event: ChangeEvent<{
    name: (keyof T & `is${ string }`) | keyof R,
    checked: T[keyof T & `is${ string }`] | R[keyof R],
  }>) => void,
  className?: string,
}

const CheckboxField = <T = IFileUploadNotificationSettingsPayload |
  IStagingFileUploadNotificationSettingsPayload |
  IAccessRequestNotificationSettingsPayload |
  IQuestionsNotificationSettingsPayload,
  R = IQuestionsRealtimeNotificationSettingsPayload,
>({
  name,
  dataTest,
  value: checked,
  label,
  disabled,
  onChange,
  className,
}: IProps<T, R>) => (
  <Checkbox
    // @ts-ignore
    name={ name }
    dataTest={ dataTest }
    checked={ checked }
    label={ label }
    className={ cn(styles.inlineBlock, styles.labelFixedWidth, className) }
    labelClassName={ styles.label }
    disabled={ disabled }
    onChange={ onChange }
  />
);

export default CheckboxField;
