import { domain } from '@/Framework/config';
import env from '@/Framework/env/env';
import { IDealroadshowConfig } from '@/dealroadshow/domain/vo/IDealroadshowConfig';

const config: IDealroadshowConfig = {
  name: 'Deal Roadshow',
  code: 'dealroadshow',
  abbr: 'DRS',
  supportEmail: env('CREDENTIALS__DEAL_ROADSHOW__SUPPORT__EMAIL'),
  supportPhoneNumber: env('CREDENTIALS__DEAL_ROADSHOW__SUPPORT__PHONE'),
  hostname: `${ domain.drsSubdomain }.${ domain.globalDomain }`,
  ga: {
    trackingId: env('GA__TRACKING_ID_DEALROADSHOW'),
  },
  gtm: {
    trackingId: env('FRONTEND___GOOGLE_TAG_MANAGER__TENANT_DEALROADSHOW__GTM_ID'),
  },
  options: {
    isNewApp: false,
  },
  isConsentForEDeliveryRequired: false,
  supportChatId: env('FRONT_APP__CHAT_ID_TENANT_DEALROADSHOW'),
};

export default config;
