import React from 'react';
import { Icon, IconType, Tooltip } from '@dealroadshow/uikit';
import dmPortalUrl from '@/dmPortal/infrastructure/url/dmPortalUrl';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import styles from '@/dataroom/ui/components/Dataroom/Sidebar/Footer/footer.scss';
import DataroomTenantConfig from '@/dataroom/application/config/DataroomTenantConfig';

function Home() {
  const { tenant } = useDataroomTenantContext();
  const { tenantLabel } = DataroomTenantConfig.fromCode(tenant).options;

  const action = (
    <a
      href={ dmPortalUrl.getUrl(`/${ tenant }`) }
      className={ styles.leftAlignedAction }
      data-test="homeButton"
    >
      <Icon type={ IconType.homeOutlined } />
    </a>
  );

  return (
    <div className={ styles.leftAlignedIcon }>
      <Tooltip
        content={ `Return to My ${ tenantLabel }s` }
        placement="top"
      >
        { action }
      </Tooltip>
    </div>
  );
}

export default Home;
