import React from 'react';

import Button, { variantTypes } from '@/Framework/UI/Atoms/Button';
import Modal from '@/dataroom/ui/common/Questions/Modal';

import styles from './cancelChangesModal.scss';

interface IProps {
  hideCanceledModal: () => void,
  cancelChanges: () => void,
  isVisible: boolean,
  modalText?: string,
  title?: string,
  modalTitle?: string,
  discardButtonClassName?: string,
}

const CancelChangesModal = ({
  hideCanceledModal,
  cancelChanges,
  isVisible,
  title = 'Discard',
  modalTitle = 'Are you sure?',
  discardButtonClassName = styles.discardButton,
  modalText = 'Any question that has not been submitted will be discarded.',
}: IProps) => {
  const footer = (
    <>
      <Button
        variant={ variantTypes.action }
        className={ discardButtonClassName }
        onClick={ cancelChanges }
        title={ title }
        dataTest="discardButton"
      />
      <Button
        variant={ variantTypes.text }
        onClick={ hideCanceledModal }
        title="Cancel"
        dataTest="cancelButton"
      />
    </>
  );

  if (!isVisible) {
    return null;
  }

  return (
    <Modal
      className={ styles.cancelChangesModal }
      onCloseClicked={ hideCanceledModal }
      isVisible={ isVisible }
      title={ modalTitle }
      closeOnEsc={ false }
      modalBodyClassName={ styles.modalBody }
      footer={ footer }
      dataTest="cancelQuestionChangesModal"
      isBaseModal
    >
      { modalText }
    </Modal>
  );
};

export default CancelChangesModal;
