import React from 'react';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { ResponsiveModal as Modal } from '@dealroadshow/uikit';
import styles from './deleteModal.scss';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';

interface IProps {
  title?: string,
  items: IFilesystemListItem[],
  className?: string,
  closeModal: () => void,
  children: () => React.ReactElement,
}

const DeleteModal = ({ title, items, className, closeModal, children }: IProps) => {
  const getModalTitle = () => {
    if (items.length > 1) {
      return `Delete ${ items.length } selected items`;
    }

    return `Delete "${ items[0].name }"`;
  };

  if (isEmpty(items)) {
    return null;
  }

  return (
    <Modal
      title={ title || getModalTitle() }
      isVisible
      className={ cn(styles.deleteModal, className) }
      onCloseClicked={ closeModal }
      dataTest="deleteModal"
    >
      { children }
    </Modal>
  );
};

export default DeleteModal;
