import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import { useFilesystemContext } from '@/dataroom/application/filesystem/filesystemActions/FilesystemContext';
import { usePrimaryFolderTreeContext } from '@/dataroom/application/folderTree/PrimaryFolderTreeContext';
import { useStagingFolderTreeContext } from '@/dataroom/application/folderTree/StagingFolderTreeContext';
import Alert from '@/dataroom/ui/components/Dataroom/components/Alert';
import Button, { variantTypes } from '@/Framework/UI/Atoms/Button';
import { Spinner, Tooltip, ResponsiveModal as Modal, Icon, IconType, Checkbox } from '@dealroadshow/uikit';
import ChangePermissions from '@/dataroom/ui/common/DataroomExplorer/Modals/ChangePermissions';
import { getAlert } from '../CopyMoveModal/alert';
import { getConfirmation } from '../CopyMoveModal/confirmation';
import ConflictModal from '../ConflictModal';
import { conflictTypes } from '../ConflictModal/constants';
import { activeItemsInitial, getActiveItems, getInfoText } from './helpers';
import * as managePermissions from '@/dataroom/domain/managePermissions';
import { FilesystemTable, DestinationTable } from './DragAndDropTable';
import { Action } from '@/dataroom/domain/vo/filesystem/Action';
import { getNodeItemById } from '@/dataroom/domain/filesystem';
import { IChangePermissionsType } from '@/dataroom/domain/vo/filesystem/ChangePermissionsType';
import { IFolderTree } from '@/dataroom/domain/vo/filesystem/FolderTree';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import { Area } from '@/dataroom/domain/vo/Area';

import changePermissionsStyles
  from '@/dataroom/ui/common/DataroomExplorer/Modals/ChangePermissions/changePermissions.scss';
import styles from './dragAndDropMoveModal.scss';

interface IProps {
  filesystemItems: Array<IFilesystemListItem | IFolderTree>,
  closeModal: () => void,
  destinationFolder: IFilesystemListItem | IFolderTree,
}

const DragAndDropMoveModal = (
  {
    filesystemItems,
    closeModal,
    destinationFolder,
  }: IProps,
) => {
  const { move } = useFilesystemContext();
  const { tenant } = useDataroomTenantContext();
  const { primaryFolderTree } = usePrimaryFolderTreeContext();
  const { stagingFolderTree } = useStagingFolderTreeContext();
  const { currentUser } = useCurrentUserContext();

  const sourceArea = useRef(primaryFolderTree ? Area.Primary : Area.Staging);
  const fromFolder = useRef(null);
  const [activeArea, setActiveArea] = useState(sourceArea.current);
  const [permissions, setPermissions] = useState<IChangePermissionsType>({});
  const [activeItems, setActiveItems] = useState(activeItemsInitial);
  const [isPermissionChangeVisible, setIsPermissionChangeVisible] = useState(false);
  const [isUnderstand, setIsUnderstand] = useState(false);

  const {
    foldersCanMove,
    filesCanMove,
    foldersCanNotMove,
    filesCanNotMove,
  } = activeItems;
  const { fetch, reset, isFetching } = move;
  const {
    allItems,
    selectedItems,
    disabledIds,
    defaultCheckboxGroupName,
    ...permissionGroups
  } = permissions;

  const userHasSystemManageAccess = managePermissions.canManageSettings(currentUser);
  const isUserAdminLite = managePermissions.canManageSettingsLite(currentUser);
  const fromStagingToPrimary = sourceArea.current === Area.Staging && !destinationFolder.isStaging;

  const foldersCanMoveNumber = foldersCanMove.length;
  const filesCanMoveNumber = filesCanMove.length;
  const itemsCanMoveNumber = foldersCanMoveNumber + filesCanMoveNumber;
  const itemsCanNotMoveNumber = filesystemItems?.length - itemsCanMoveNumber;

  useEffect(() => {
    if (filesystemItems?.length) {
      reset();
      const isCurrentStaging = filesystemItems[0].isStaging;
      sourceArea.current = isCurrentStaging ? Area.Staging : Area.Primary;
      setActiveArea(destinationFolder.isStaging ? Area.Staging : Area.Primary);
      setActiveItems(getActiveItems(filesystemItems, [primaryFolderTree, stagingFolderTree]));
      fromFolder.current = getNodeItemById(
        isCurrentStaging ? stagingFolderTree : primaryFolderTree,
        filesystemItems[0].parentFolderId,
      );
    }
  }, [filesystemItems]);

  const onUnderstandChange = (e) => {
    setIsUnderstand(e.target.checked);
  };

  const onPermissionsChange = () => {
    setIsUnderstand(false);
    setIsPermissionChangeVisible(true);
  };

  const onCloseModal = () => {
    closeModal();
    move.reset();
    setIsUnderstand(false);
    setActiveItems(activeItemsInitial);
  };

  const itemsInfo = (
    <div className={ styles.infoSection }>
      { getInfoText(foldersCanMoveNumber, filesCanMoveNumber) }
      { itemsCanNotMoveNumber && itemsCanMoveNumber ? (
        <Tooltip
          content={ `
            ${ itemsCanNotMoveNumber } other selected item${ itemsCanNotMoveNumber > 1 ? 's' : '' } could not be
             moved due to insufficient permissions
          ` }
          containerClassName={ styles.warningIconContainer }
        >
          <Icon
            type={ IconType.warning }
            className={ styles.warningIcon }
          />
        </Tooltip>
      ) : null }
    </div>
  );

  const content = (
    <div className={ styles.contentWrp }>
      <div className={ styles.bodyPadding }>
        <Alert>
          { getAlert(
            tenant,
            Action.Move,
            sourceArea.current,
            activeArea,
          ) }
        </Alert>
      </div>
      <div className={ styles.bodyPadding }>
        { itemsInfo }
      </div>
      <FilesystemTable
        itemsCanNotMove={ [...foldersCanNotMove, ...filesCanNotMove] }
        itemsCanMove={ [...foldersCanMove, ...filesCanMove] }
      />
      <div className={ styles.bodyPadding }>
        <div className={ cn(styles.infoSection, styles.separator) }>
          to
        </div>
      </div>
      <DestinationTable destinationFolder={ destinationFolder } />
      { userHasSystemManageAccess && isPermissionChangeVisible && (
        <>
          <div className={ cn(styles.bodyPadding, changePermissionsStyles.description) }>
            The following table displays permissions of the destination folder. The selected files and folders will
            inherit these permissions after this action is performed.
          </div>
          <ChangePermissions
            folderId={ destinationFolder?.id }
            onChange={ setPermissions }
            className={ styles.permissionsWrp }
          />
        </>
      ) }
      <Spinner
        isVisible={ isFetching }
        overlay
      />
    </div>
  );

  const footer = (
    <div className={ styles.modalFooter }>
      <Checkbox
        dataTest="understand"
        className={ styles.agree }
        onChange={ onUnderstandChange }
        disabled={ isFetching }
        checked={ isUnderstand }
        label={ getConfirmation(
          tenant,
          Action.Move,
          sourceArea.current,
          activeArea,
          isPermissionChangeVisible,
        ) }
      />
      <div className={ cn(styles.left, styles.buttonsWrp) }>
        <Button
          type="submit"
          variant={ variantTypes.action }
          onClick={ () => {
            fetch({
              filesystemItems: [...foldersCanMove, ...filesCanMove],
              destinationFolder,
              permissionGroups,
              successCallback: onCloseModal,
              // @ts-ignore
              payload: fromStagingToPrimary ? { fromStagingToPrimary } : null,
            });
          } }
          disabled={ !isUnderstand || !destinationFolder || isFetching || itemsCanMoveNumber < 1 }
          dataTest="submitButton"
        >
          { isPermissionChangeVisible && (<>Save Permissions & </>) }
          <span className={ styles.capitalize }>
            { `${ Action.Move } ${ itemsCanMoveNumber > 0 ? `(${ itemsCanMoveNumber })` : '' }` }
          </span>
        </Button>
        <Button
          variant={ variantTypes.text }
          onClick={ onCloseModal }
          title="Cancel"
          dataTest="cancelButton"
        />
      </div>
      { !isPermissionChangeVisible && (
        <div className={ styles.right }>
          <Tooltip
            content={ 'Not available based on your permissions' }
            disabled={ !isUserAdminLite }
            placement="top"
          >
            <Button
              className={ styles.changePermissionsButton }
              variant={ variantTypes.text }
              disabled={ !destinationFolder || isFetching || !userHasSystemManageAccess || !itemsCanMoveNumber }
              onClick={ onPermissionsChange }
              dataTest="dragAndDropChangePermissionsButton"
            >
              <Icon
                className={ styles.changePermissionsIcon }
                type={ IconType.lock }
              />
              <span className={ styles.changePermissionsTitle }>Change Permissions</span>
            </Button>
          </Tooltip>
        </div>
      ) }
    </div>
  );

  if (move.conflictItems.length) {
    return (
      <ConflictModal
        onCloseConflictModal={ onCloseModal }
        action={ fetch }
        conflictType={ conflictTypes.move }
        successItems={ move.successItems }
        conflictItems={ move.conflictItems }
        destinationFolder={ destinationFolder }
        permissionGroups={ permissionGroups }
        isFetching={ isFetching }
        from={ fromFolder.current }
        fromStagingToPrimary={ fromStagingToPrimary }
      />
    );
  }

  return filesystemItems?.length ? (
    <Modal
      className={ styles.dragAndDropMoveModal }
      onCloseClicked={ onCloseModal }
      title={ `Move to: "${ destinationFolder.name }"` }
      footer={ footer }
      isVisible
      dataTest={ `DragAndDrop${ Action.Move }Modal` }
    >
      <div>{ content }</div>
    </Modal>
  ) : null;
};

export default DragAndDropMoveModal;
