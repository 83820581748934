import cn from 'classnames';
import React from 'react';
import { ProgressBar } from '@dealroadshow/uikit';
import styles from './uploaderProgressBar.scss';
import ProgressBarWrp from './ProgressBarWrp';
import Status from './Status';

interface IProps {
  status: string,
  processingPercentage: number,
  uploadPercentage: number,
  isWarning?: boolean,
  isError?: boolean,
}

const UploaderProgressBar = (
  {
    processingPercentage,
    uploadPercentage,
    isWarning = false,
    status,
    isError,
  }: IProps,
) => (
  <ProgressBarWrp>
    <Status isError={ isError }>{ status }</Status>
    <ProgressBar
      className={ styles.progressBar }
      filledClassName={ styles.filled }
      value={ processingPercentage }
    >
      <div
        className={ cn(styles.fileUploading, {
          [styles.filledWithoutAnimation]: !uploadPercentage, // no animation in case of reset
          [styles.warning]: isWarning,
        }) }
        style={ { width: `${ uploadPercentage }%` } }
      />
    </ProgressBar>
  </ProgressBarWrp>
);

export default UploaderProgressBar;
