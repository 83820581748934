import React, { createContext, useContext } from 'react';
import useRemove from '@/dataroom/application/redaction/useRemove';
import useEdit from '@/dataroom/application/redaction/useEdit';
import useListing from '@/dataroom/application/redaction/useListing';

const useDataroomRedaction = () => ({
  remove: useRemove(),
  edit: useEdit(),
  listing: useListing(),
});

type DataroomRedactionContextType = ReturnType<typeof useDataroomRedaction>;

export const DataroomRedactionContext = createContext<DataroomRedactionContextType>(null);

export function useDataroomRedactionContext() {
  const context = useContext(DataroomRedactionContext);
  if (!context) {
    throw new Error('useDataroomRedactionContext must be used within a DataroomRedactionContextProvider');
  }
  return context;
}

interface IProps {
  children: React.ReactNode,
}

const DataroomRedactionContextProvider = ({ children }: IProps) => (
  <DataroomRedactionContext.Provider value={ useDataroomRedaction() }>
    { children }
  </DataroomRedactionContext.Provider>
);

export default DataroomRedactionContextProvider;
