import { stringHandler } from '@dealroadshow/data-scrubber-js/dist/handler/stringHandler';
import { emailHandler } from '@dealroadshow/data-scrubber-js/dist/handler/emailHandler';
import { domain } from '@/Framework/config';
import env from '@/Framework/env/env';
import dataroomConfig from '@/dataroom/application/config/config';
import finsightConfig from '@/finsight/application/config/config';
import condorConfig from '@/condor/application/config/config';
import usersConfig from '@/users/application/config/config';
import dmPortalConfig from '@/dmPortal/application/config/config';
import dealroadshowConfig from '@/dealroadshow/application/config/config';
import evercallConfig from '@/evercall/application/config/config';
import allocateConfig from '@/allocate/application/config/config';
import orderBookConfig from '@/orderBook/application/config/config';
import openmarketConfig from '@/openMarket/application/config/config';

const tenant = {
  ...dataroomConfig.tenant,
  finsight: finsightConfig,
  condor: condorConfig,
  users: usersConfig,
  dmPortal: dmPortalConfig,
  dealroadshow: dealroadshowConfig,
  tenantEvercall: evercallConfig,
  allocate: allocateConfig,
  orderBook: orderBookConfig,
  openmarket: openmarketConfig,
};

const config = {
  host: domain.globalDomain,
  defaultTenant: tenant.finsight.code,

  /*
  * Domains
  * */
  ...domain,

  /*
  * Api
  * */
  api: {
    proxy: {
      rpc: {
        url: '/proxy/api/rpc',
      },
      rest: {
        url: '/proxy/api',
      },
    },
    rpc: {
      url: `${ domain.protocol }//${ domain.apiSubdomain }.${ domain.globalDomain }/rpc`,
    },
    rest: {
      url: `${ domain.protocol }//${ domain.apiSubdomain }.${ domain.globalDomain }`,
    },
  },

  /*
  * Assets
  * */
  assets: {
    location: '_next/static/frontend/static',
  },

  /*
  * Session
  * */
  session: {
    idleTimeout: 5 * 60 * 1000, // 5m in ms
    prolongInterval: 15 * 60 * 1000, // 15m in ms
    viewerIdTtl: 10 * 30 * 24 * 60 * 60 * 1000, // 10mo in ms,
    loginEndpoints: [
      `${ domain.protocol }//${ domain.drsSubdomain }.${ domain.globalDomain }`,
      `${ domain.protocol }//${ domain.usersSubdomain }.${ domain.globalDomain }/login`,
      `${ domain.protocol }//${ domain.usersSubdomain }.${ domain.globalDomain }/login/condor`,
      `${ domain.protocol }//${ domain.condorSubdomain }.${ domain.globalDomain }/login/condor`,
    ],
  },

  cookie: {
    secure: domain.protocol === 'https:', // Set secured flag for cookie only if https
  },

  ga: {
    linkerDomains: env('GA__LINKER_DOMAINS')?.split(','),
  },

  /*
  * Dealroadshow
  * */
  drs: {
    presentation: {
      changeSlide: {
        debounce: 200,
      },
      prefetchSlides: {
        debounce: 150,
        amount: 7,
      },
      idleTime: 8 * 60 * 1000, // 8m in ms
      idleLogoutTime: 30 * 60 * 1000, // 30m in ms
      maxIdleTime: 3 * 60 * 60 * 1000, // 3h in ms
    },
    condor: {
      base: '/core/dealroadshow',
    },
  },
  finsight: {
    api: {
      url: `${ domain.protocol }//${ domain.globalDomain }/api`,
    },
    condor: {
      base: '/finsight/finsight',
    },
  },
  users: {
    condor: {
      base: '/finsight/users',
    },
  },
  condor: {
    base: '/core',
    finsight: '/core/finsight',
  },

  /*
   * Socket
   */
  socket: {
    // We force socket connection to dummy localhost in case the variables sre not yet replaced.
    // This hack can be removed after we clean up our codebase from getContainer() calls.
    // The socket connection is created during the build time, because we do instantate it in the root scope
    // with the createAsyncCollection factory
    url: domain.protocol.includes('http')
      ? `${ domain.protocol }//${ domain.socketSubdomain }.${ domain.globalDomain }/socket`
      : 'http://localhost',
  },

  /*
  * Tenants mapping
  * */
  tenant,

  /*
   * Login endpoints list
   * */
  loginEndpoints: [
    `${ domain.protocol }//${ domain.drsSubdomain }.${ domain.globalDomain }`,
    `${ domain.protocol }//${ domain.usersSubdomain }.${ domain.globalDomain }/login`,
    `${ domain.protocol }//${ domain.usersSubdomain }.${ domain.globalDomain }/login/condor`,
    `${ domain.protocol }//${ domain.condorSubdomain }.${ domain.globalDomain }/login/condor`,
  ],

  recaptcha: {
    visible: {
      siteKey: env('RECAPTCHA_VISIBLE__SITE_KEY'),
    },
    invisible: {
      siteKey: env('RECAPTCHA_INVISIBLE__SITE_KEY'),
    },
  },

  iceServers: env('METERED__ICE_SERVERS'),

  searchEngine: {
    allowIndexing: env('SE__ALLOW_INDEXING') === 'true',
  },

  log: {
    sentry: {
      dsn: env('SENTRY_DSN'),
      debug: false,
      environment: env('SENTRY_ENVIRONMENT'),
      tracesSampleRate: 0,
      attachStacktrace: true,
      release: env('SENTRY_RELEASE'),
    },
    dataScrubber: env('DATA_SCRUBBER__CONFIG_BASE64'),
    routeScrubber: [
      ['/login/investor/complete_profile/e/:entryCode', { entryCode: stringHandler() }],
      ['/e/:entryCode/:slideNum?', { entryCode: stringHandler() }],
      ['/login/investor/e/:entryCode', { entryCode: stringHandler() }],
      ['/login/sso/:email', { email: emailHandler({ leaveLetters: 2, leaveDomain: true }) }],
      ['/forgot_password/:email?', { email: emailHandler({ leaveLetters: 2, leaveDomain: true }) }],
      ['/forgot_password_success/:email', { email: emailHandler({ leaveLetters: 2, leaveDomain: true }) }],
      ['/reset_password/:email/:hash', {
        email: emailHandler({ leaveLetters: 2, leaveDomain: true }),
        hash: stringHandler(),
      }],
      ['/register_success/:email', { email: emailHandler({ leaveLetters: 2, leaveDomain: true }) }],
      ['/register/:email', { email: emailHandler({ leaveLetters: 2, leaveDomain: true }) }],
      ['/complete_profile/:email/:activationCode', {
        email: emailHandler({ leaveLetters: 2, leaveDomain: true }),
        activationCode: stringHandler(),
      }],
      ['/complete_profile/sso/:email/:activationCode', {
        email: emailHandler({ leaveLetters: 2, leaveDomain: true }),
        activationCode: stringHandler(),
      }],
    ],
  },
};

export default config;
