import React from 'react';
import cn from 'classnames';
import DownloadButton, {
  variantTypes as downloadButtonVariantTypes,
} from '@/dataroom/ui/common/Download/DownloadButton';
import Button, { variantTypes } from '@/Framework/UI/Atoms/Button';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import { isViewOnly } from '@/dataroom/domain/filesystemPermissions';
import { responsiveModalStyles } from '@dealroadshow/uikit';
import styles from './previewNotAvailable.scss';
import DataroomTenantConfig from '@/dataroom/application/config/DataroomTenantConfig';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import { Area } from '@/dataroom/domain/vo/Area';

interface IProps {
  item: IFilesystemListItem,
  closeModal: (e) => void,
  footerRefCallback: (ref: HTMLDivElement) => void,
}

const PreviewNotAvailable = ({ item, closeModal, footerRefCallback }: IProps) => {
  const { tenant } = useDataroomTenantContext();
  const { tenantLabelSm } = DataroomTenantConfig.fromCode(tenant).options;
  const isItemViewOnly = isViewOnly(item);

  return (
    <>
      <div className={ styles.contentWrp }>
        <div className={ styles.disclaimerWrp }>
          { isItemViewOnly && `Please contact ${ tenantLabelSm } administrator for download permissions.` }
          { !isItemViewOnly && 'Please download the file to view content.' }
        </div>
      </div>
      <div
        className={ cn(styles.buttonsWrp, responsiveModalStyles.modalFooter) }
        ref={ footerRefCallback }
      >
        { isItemViewOnly && (
          <Button
            variant={ variantTypes.action }
            title="Ok"
            onClick={ closeModal }
            dataTest="previewOkButton"
          />
        ) }
        { !isItemViewOnly && (
          <>
            <DownloadButton
              variant={ downloadButtonVariantTypes.action }
              selected={ [item] }
              areas={ [item.isStaging ? Area.Staging : Area.Primary] }
              onFinish={ closeModal }
              dataTest="previewDownloadButton"
            />
            <Button
              variant={ variantTypes.text }
              title="Cancel"
              onClick={ closeModal }
              dataTest="previewCancelButton"
            />
          </>
        ) }
      </div>
    </>
  );
};

export default PreviewNotAvailable;
