import React, { useState, useEffect, useMemo } from 'react';
import FolderTree from '@/dataroom/ui/components/Dataroom/components/FolderTree';
import { Spinner } from '@dealroadshow/uikit';
import DnDWrapper from '@/dataroom/ui/common/DataroomExplorer/DnDWrapper';
import Node from './Node';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { getNodePathById } from '@/dataroom/domain/filesystem';
import { canSelectedDrop, isViewOnly } from '@/dataroom/domain/filesystemPermissions';
import { useCurrentFolderContext } from '@/dataroom/application/CurrentFolderContext';
import { useDnDFileIndexesContext } from '@/dataroom/application/DnDFileIndexesContext';
import { IFolderTree } from '@/dataroom/domain/vo/filesystem/FolderTree';
import styles from './sidebarFolderTree.scss';
import navigationStyles from '@/dataroom/ui/components/Dataroom/Sidebar/Navigation/navigation.scss';

interface IProps {
  folderTree: IFolderTree,
  isFetching: boolean,
}

const SidebarFolderTree = ({ folderTree, isFetching }: IProps) => {
  const [openNodes, setOpenNodes] = useState([]);
  const { isFetching: isDataroomFetching } = useDataroomContext();
  const { currentFolder } = useCurrentFolderContext();
  const { isDnDFileIndexesActive } = useDnDFileIndexesContext();

  const NodeComponent = useMemo(() => DnDWrapper(
    Node,
    ({
      item,
    }) => ({
      id: item.id,
      data: [item],
      disabled: isViewOnly(item),
    }),
    ({ item }) => ({
      id: item.id,
      data: item,
    }),
    ({ item }, selectedRows) => canSelectedDrop(selectedRows, item),
  ), []);

  useEffect(() => {
    const currentPath = currentFolder && folderTree ? getNodePathById(folderTree, currentFolder.id) : [];
    setOpenNodes(currentPath.map((item) => item.id));
  }, [folderTree, currentFolder]);

  return (
    <div
      className={ styles.folderTreeWrp }
      data-test="sidebarFileTree"
    >
      { (isFetching || isDataroomFetching) && <Spinner overlay /> }
      { folderTree.id && (
        <FolderTree
          folderTree={ folderTree }
          className={ navigationStyles.folderTree }
          openNodes={ openNodes }
          NodeComponent={ isDnDFileIndexesActive ? Node : NodeComponent }
        />
      ) }
    </div>
  );
};

export default SidebarFolderTree;
