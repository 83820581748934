import { domain } from '@/Framework/config';
import env from '@/Framework/env/env';
import { IDmPortalConfig } from '@/dmPortal/domain/vo/IDmPortalConfig';
import { featureToggle as dmPortalFeatureToggle } from './featureToggle';

const config: IDmPortalConfig = {
  name: 'Deal Manager Portal',
  code: 'dmPortal',
  supportEmail: env('CREDENTIALS__DM_PORTAL__SUPPORT__EMAIL'),
  hostname: `${ domain.dmPortalSubdomain }.${ domain.globalDomain }`,
  ga: {
    trackingId: env('GA__TRACKING_ID_DEALROADSHOW'),
  },
  gtm: {
    trackingId: env('FRONTEND___GOOGLE_TAG_MANAGER__TENANT_DEALROADSHOW__GTM_ID'),
  },
  supportChatId: env('FRONT_APP__CHAT_ID_TENANT_DM_PORTAL'),
  api: {
    internal: `http://${ env('DM_PORTAL_WS__HOSTNAME_INTERNAL') }`,
  },
  featureToggle: dmPortalFeatureToggle,
};

export default config;
