import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import noop from 'lodash/noop';
import dataroomUrl from '@/dataroom/infrastructure/dataroomUrl';
import CheckboxArrayContext from '@/Framework/UI/Molecules/CheckBoxArray/CheckboxArrayContext';
import { getPermissionGroupsInitialValues } from '@/dataroom/ui/common/PermissionGroups/helpers';
import Button, { variantTypes } from '@/Framework/UI/Atoms/Button';
import { Spinner, Icon, IconType, DataTable, dataTableStyles } from '@dealroadshow/uikit';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import { useFilesystemContext } from '@/dataroom/application/filesystem/filesystemActions/FilesystemContext';
import columns from './columns';
import { device } from '@/Framework/browser/device';
import { IChangePermissionsType } from '@/dataroom/domain/vo/filesystem/ChangePermissionsType';
import styles from './changePermissions.scss';
import DataroomTenantConfig from '@/dataroom/application/config/DataroomTenantConfig';

interface IProps {
  folderId: number,
  onInit?: (data?) => void,
  onChange?: (values: IChangePermissionsType) => void,
  className?: string,
  containerClassName?: string,
  isFolder?: boolean,
  permissionsName?: string,
  permissionsTableTitle?: string,
}

const ChangePermissions = (
  {
    folderId,
    onInit = noop,
    onChange,
    className = '',
    containerClassName = '',
    isFolder,
    permissionsName = 'customPermissions',
    permissionsTableTitle,
  }: IProps,
) => {
  const [initialValues, setInitialValues] = useState({});
  const { dataroom: { name: dataroomName } } = useDataroomContext();
  const { tenant } = useDataroomTenantContext();

  const {
    // @ts-ignore
    [permissionsName]: {
      isFetching,
      collection,
      getPermissions,
    },
  } = useFilesystemContext();

const getInitialValues = (collection) => collection && getPermissionGroupsInitialValues(collection.map((item) => ({
    key: item.permissionGroupId,
    permissionGroup: item.folderPermission || item.filePermission,
  })));

  const getPermissionGroups = () => {
    folderId && getPermissions(folderId, isFolder).then((data) => { onInit(getInitialValues(data)); });
  };

  useEffect(() => {
    getPermissionGroups();
  }, [folderId]);

  useEffect(() => {
    setInitialValues(getInitialValues(collection));
  }, [collection]);

  const { tenantUrlPrefix } = DataroomTenantConfig.fromCode(tenant).options;

  const handleOpenPermission = () => {
    const url = dataroomUrl(tenant).getUrl(`/${ tenantUrlPrefix }/${ dataroomName }/settings/permission-groups`);
    window.open(url, '_blank');
  };
  const createPermissionsButton = (
    <Button
      variant={ variantTypes.link }
      onClick={ () => handleOpenPermission() }
      dataTest="createPermissionsButton"
    >
      <span>Click here</span>
    </Button>
  );

  return (
    <CheckboxArrayContext
      onChange={ onChange }
      // @ts-ignore
      initialValues={ initialValues }
    >
      <DataTable
        columns={ columns(permissionsTableTitle) }
        className={ cn(
          styles.dataTable,
          dataTableStyles.isHoverable,
          className,
          { [styles.isTouch]: device().mobile() },
          { [styles.isEmpty]: !collection.length },
        ) }
        containerClassName={ cn(dataTableStyles.dataTableContainer, styles.containerClassName, containerClassName) }
        data={ collection }
        isHeaderVisible={ !!collection.length }
        sortBy={ ' ' }
        sortOrder="asc"
        loadingComponent={ Spinner }
        isFetching={ isFetching }
        emptyMessage={
          (
            <div className={ styles.emptyMessage }>
              No custom permission groups available. <br />
              { createPermissionsButton } to create a custom permission group. <br />
              <Button
                className={ styles.refreshBtn }
                variant={ variantTypes.link }
                onClick={ () => getPermissionGroups() }
                dataTest="refreshPermissionsButton"
              >
                <Icon type={ IconType.refresh } />
                <span>Refresh</span>
              </Button>
            </div>
          )
        }
        dataTest="changePermissionTable"
      />
    </CheckboxArrayContext>
  );
};

export default ChangePermissions;
