import React from 'react';
import { ResponsiveModal as Modal } from '@dealroadshow/uikit';
import LinkForm from './LinkForm';
import styles from './createLinkModal.scss';
import { useFilesystemContext } from '@/dataroom/application/filesystem/filesystemActions/FilesystemContext';

interface IProps {
  isVisible: boolean,
  closeModal: () => void,
}

const CreateLinkModal = ({ isVisible, closeModal }: IProps) => {
  const { createLink: { isFetching, createLink } } = useFilesystemContext();

  if (!isVisible) {
    return null;
  }

  return (
    <Modal
      title="Create New Hyperlink"
      isVisible
      className={ styles.createLinkModal }
      onCloseClicked={ closeModal }
      dataTest="createLinkModal"
    >
      { (footerRefCallback) => (
        <LinkForm
          isFetching={ isFetching }
          closeModal={ closeModal }
          submitButtonText="Create"
          onSubmit={ createLink }
          initialValues={ {
            name: '',
            href: '',
          } }
          name="linkFormCreate"
          footerRefCallback={ footerRefCallback }
        />
      ) }
    </Modal>
  );
};

export default CreateLinkModal;
