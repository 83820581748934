import React, { useState, useEffect, ChangeEvent } from 'react';
import cn from 'classnames';
import noop from 'lodash/noop';
import { getDigestNotificationSummary } from '../helpers';
import { DAY_OF_WEEK_LIST, HOURS_OPTIONS } from '../common/constants';
import { Spinner } from '@dealroadshow/uikit';
import CheckboxField from '../common/CheckboxField';
import SelectField from '../common/SelectField';
import { IDigestNotificationSettingsPayload } from '@/dataroom/domain/vo/emailAlerts/DigestEmailAlerts';
import { ITimezone } from '@/Framework/TimeZone/vo/Timezone';
import styles from './digestAlerts.scss';

interface IProps<T> {
  name?: string,
  initialValues: T,
  isFetching?: boolean,
  isDisabled?: boolean,
  timezone?: ITimezone,
  onChange: (value: T) => void,
}

const DigestAlerts = <T extends IDigestNotificationSettingsPayload = IDigestNotificationSettingsPayload>({
  name = 'dataroomDigestAlertsForm',
  initialValues,
  isFetching = false,
  isDisabled,
  timezone,
  onChange = noop,
}: IProps<T>) => {
  const [digestAlertsState, setDigestAlertsState] = useState<T>(initialValues);

  useEffect(() => {
    onChange(digestAlertsState);
  }, [digestAlertsState]);

  useEffect(() => {
    isDisabled
      ? setDigestAlertsState({ ...initialValues, isActiveDigestAlerts: true, isDigestDaily: false })
      : setDigestAlertsState(initialValues);
  }, [initialValues, isDisabled]);

  const handleCheckboxFieldChange = (
    event: ChangeEvent<{
      name: keyof T & `is${ string }`,
      checked: T[keyof T & `is${ string }`],
    }>,
  ) => {
    const { name: field, checked: value } = event.target;

    const state: T = {
      ...digestAlertsState,
      [field]: value,
    };

    if (field === 'isActiveDigestAlerts') {
      state.isActiveDigestAlerts = true;
      state.isDigestDaily = false;
      state.isDigestWeekly = false;
    } else {
      state.isActiveDigestAlerts = false;
    }

    if (
      !state.isActiveDigestAlerts &&
      !state.isDigestDaily &&
      !state.isDigestWeekly
    ) {
      state.isActiveDigestAlerts = true;
    }

    setDigestAlertsState(state);
  };

  const handleSelectFieldChange = (
    name: keyof Omit<T, keyof T & `is${ string }`>,
    value: T[keyof Omit<T, keyof T & `is${ string }`>],
  ) => {
    if (!value) return;

    setDigestAlertsState({
      ...digestAlertsState,
      [name]: value,
    });
  };

  return (
    <div data-test={ name }>
      <div className={ styles.itemAlerts }>
        <CheckboxField<T>
          name="isActiveDigestAlerts"
          dataTest="noneCheckbox"
          label="None"
          disabled={ isDisabled }
          value={ digestAlertsState.isActiveDigestAlerts }
          onChange={ handleCheckboxFieldChange }
        />
      </div>
      <div className={ styles.itemAlerts }>
        <CheckboxField<T>
          name="isDigestDaily"
          dataTest="dailyCheckbox"
          label="Daily summary"
          disabled={ isDisabled }
          value={ digestAlertsState.isDigestDaily }
          onChange={ handleCheckboxFieldChange }
        />
        <div className={ cn(styles.inlineBlock, styles.wrpSelect) }>
          <SelectField<T>
            name="digestDailyTime"
            dataTest="digestDailyTime_digestAlertSelect"
            selectClassName={ styles.timeSelect }
            options={ HOURS_OPTIONS }
            disabled={ !digestAlertsState.isDigestDaily || isDisabled }
            value={ digestAlertsState.digestDailyTime }
            onChange={ handleSelectFieldChange }
          />
        </div>
      </div>
      <div className={ styles.itemAlerts }>
        <CheckboxField<T>
          name="isDigestWeekly"
          dataTest="weeklyCheckbox"
          label="Weekly summary"
          disabled={ isDisabled }
          value={ digestAlertsState.isDigestWeekly }
          onChange={ handleCheckboxFieldChange }
        />
        <div className={ cn(styles.inlineBlock, styles.wrpSelect) }>
          <SelectField<T>
            name="digestWeeklyTime"
            dataTest="digestWeeklyTime_digestAlertSelect"
            selectClassName={ styles.timeSelect }
            options={ HOURS_OPTIONS }
            disabled={ !digestAlertsState.isDigestWeekly || isDisabled }
            value={ digestAlertsState.digestWeeklyTime }
            onChange={ handleSelectFieldChange }
          />
        </div>
        <div className={ cn(styles.inlineBlock, styles.dayWeek) }>
          <SelectField<T>
            name="digestWeeklyDay"
            dataTest="digestWeeklyDay_digestAlertSelect"
            selectClassName={ styles.selectDaysOfWeek }
            options={ DAY_OF_WEEK_LIST }
            disabled={ !digestAlertsState.isDigestWeekly || isDisabled }
            value={ digestAlertsState.digestWeeklyDay }
            onChange={ handleSelectFieldChange }
          />
        </div>
      </div>
      { timezone && (
        <div className={ styles.footer }>
          <span className={ styles.strong }>
            Summary:
          </span> { getDigestNotificationSummary(digestAlertsState, timezone.abbr) }
        </div>
      ) }
      <Spinner
        overlay
        isVisible={ isFetching }
      />
    </div>
  );
};

export default DigestAlerts;
