import parse from 'date-fns/parse';
import { isValid } from 'date-fns';
import getZonedUTCDate from './getZonedUTCDate';
import { FULL_DATETIME_FORMAT, YEAR_MONTH_DAY_TIME_FORMAT } from '@/Framework/DateTime/dateFormats';

/**
 * Parse date from string. Parses in UTC by default if timezone value is not specified.
 * @example
 * parseDateString('2023-07-24 21:16:54', 'America/New_York'); // Date
 * parseDateString('2023-07-24', 'America/New_York', 'yyyy-MM-dd'); // Date
 */
export const parseDateString = (
  date: string,
  timezone: string = null,
  dateFormat = YEAR_MONTH_DAY_TIME_FORMAT,
): Date => {
  const parsedDate = parse(date, dateFormat, new Date());

  return getZonedUTCDate(timezone, parsedDate);
};

/**
 * Remove timezone abbr from string since date-fns can't read them
 */
const cutTimezoneAbbr = (date: string): string => date.slice(0, -4);

/**
 * Parse date from string in legacy format
 * @example
 * parseLegacyDateString('21-Aug-2022 20:00:00 EDT', 'America/New_York'); // Date
 */
export const parseLegacyDateString = (date: string, timezone: string): Date => {
  return parseDateString(cutTimezoneAbbr(date), timezone, FULL_DATETIME_FORMAT);
};

/**
 * @returns The parsed Date or new Date object.
 * @example
 * parseOrValidateDateString('2023-07-24 21:16:54 GMT+0700', 'America/New_York'); // Date
 * parseOrValidateDateString('21-Aug-2022 20:00:00 EDT', 'America/New_York'); // Date
 */
export const parseOrValidateDateString = (date: string, timezone: string): Date => {
  return isValid(new Date(date)) ? new Date(date) : parseLegacyDateString(date, timezone);
};

export default parseDateString;
