import React from 'react';
import cn from 'classnames';
import { DataTable } from '@dealroadshow/uikit';
import columns from './columns';
import { IFolderTree } from '@/dataroom/domain/vo/filesystem/FolderTree';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';

import styles from './dragAndDropTable.scss';

interface IProps {
  destinationFolder: IFilesystemListItem | IFolderTree,
}

const DestinationTable = (
  {
    destinationFolder,
  }: IProps,
) => {
  return (
    <DataTable
      isHeaderVisible={ false }
      columns={ columns }
      data={ [destinationFolder] }
      dataTest="dragAndDropDestinationFolderTable"
      className={ cn(styles.table, styles.destinationTable) }
    />
  );
};

export default DestinationTable;
