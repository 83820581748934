import React from 'react';
import { DataTable } from '@dealroadshow/uikit';
import columns from './columns';
import { IFolderTree } from '@/dataroom/domain/vo/filesystem/FolderTree';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';

import styles from './dragAndDropTable.scss';

interface IProps {
  itemsCanNotMove: Array<IFilesystemListItem | IFolderTree>,
  itemsCanMove: Array<IFilesystemListItem | IFolderTree>,
}

const FilesystemTable = (
  {
    itemsCanNotMove,
    itemsCanMove,
  }: IProps,
) => {
  return (
    <DataTable
      isHeaderVisible={ false }
      columns={ columns }
      data={ [...itemsCanNotMove, ...itemsCanMove] }
      dataTest="dragAndDropMoveTable"
      className={ styles.table }
      containerClassName={ styles.tableContainer }
      selectedRows={ itemsCanNotMove }
    />
  );
};

export default FilesystemTable;
