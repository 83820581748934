import React, { useState, useEffect } from 'react';
import { ResponsiveModal as Modal } from '@dealroadshow/uikit';
import ChangeIndexForm from './ChangeIndexForm';
import ChangeIndexConflictForm from './ChangeIndexConflictForm';
import { CHANGE_INDEX_FORM, CHANGE_INDEX_CONFLICT_FORM, changeIndexModalTitles } from './constants';
import styles from './changeIndexModal.scss';
import { useFilesystemContext } from '@/dataroom/application/filesystem/filesystemActions/FilesystemContext';
import { isFolder } from '@/dataroom/domain/filesystem';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';

interface IProps {
  item: IFilesystemListItem,
  closeModal: () => void,
}

const ChangeIndexModal = ({ item, closeModal }: IProps) => {
  const [type, setType] = useState(CHANGE_INDEX_FORM);
  const [index, setIndex] = useState(item?.fileIndexCurrent);
  const {
    fileIndexChange: {
      isFetching,
      conflictItemName,
      changeIndex,
      changeIndexOverrideAndShiftNonPinned,
      changeIndexOverrideAndShiftAll,
      checkCustomExists,
      unpinIndex,
    },
    indexLimits: {
      isFetching: isIndexLimitsFetching,
      maxFileIndex,
      maxFolderIndex,
      getIndexLimits,
      resetIndexLimits,
    },

  } = useFilesystemContext();

  const isModalVisible = item && (maxFileIndex || maxFolderIndex || isIndexLimitsFetching);
  const maxIndex = item && (isFolder(item) ? maxFolderIndex : maxFileIndex);

  useEffect(() => {
    if (item) {
      getIndexLimits().catch(handleCloseModal);
    }
  }, [item]);

  const handleCloseModal = () => {
    closeModal();
    resetIndexLimits();
    setType(CHANGE_INDEX_FORM);
  };

  const getModalContent = (footerRefCallback) => {
    switch (type) {
      case CHANGE_INDEX_CONFLICT_FORM:
        return (
          <ChangeIndexConflictForm
            isFetching={ isFetching }
            conflictItemName={ conflictItemName }
            item={ item }
            index={ index }
            closeModal={ handleCloseModal }
            changeIndexOverrideAndShiftNonPinned={ changeIndexOverrideAndShiftNonPinned }
            changeIndexOverrideAndShiftAll={ changeIndexOverrideAndShiftAll }
            footerRefCallback={ footerRefCallback }
          />
        );
      case CHANGE_INDEX_FORM:
      default:
        return (
          <ChangeIndexForm
            isFetching={ isFetching }
            isFormLoading={ isIndexLimitsFetching }
            item={ item }
            closeModal={ handleCloseModal }
            onChangeIndex={ setIndex }
            onChangeType={ setType }
            changeIndex={ changeIndex }
            checkCustomExists={ checkCustomExists }
            unpinIndex={ unpinIndex }
            initialValues={ {
              fileIndexCurrent: item.fileIndexCurrent.toString(),
            } }
            maxIndex={ maxIndex }
            footerRefCallback={ footerRefCallback }
          />
        );
    }
  };

  if (!isModalVisible) {
    return null;
  }

  return (
    <Modal
      title={ changeIndexModalTitles[type] }
      isVisible
      isLoading={ isIndexLimitsFetching }
      className={ styles.changeIndexModal }
      onCloseClicked={ handleCloseModal }
      dataTest="changeFileIndexModal"
    >
      { getModalContent }
    </Modal>
  );
};

export default ChangeIndexModal;
