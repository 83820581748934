import React from 'react';
import Button, { variantTypes } from '@/Framework/UI/Atoms/Button';
import { Modal } from '@dealroadshow/uikit';
import styles from './cancelUploadModal.scss';

interface IProps {
  hideCanceledModal: () => void,
  canceledUpload: () => void,
}

const CancelUploadModal = ({ hideCanceledModal, canceledUpload }: IProps) => {
  const footer = (
    <>
      <Button
        variant={ variantTypes.action }
        onClick={ canceledUpload }
        title="Yes, Cancel"
        dataTest="cancelUploadingButton"
      />
      <Button
        variant={ variantTypes.text }
        onClick={ hideCanceledModal }
        title="Continue Uploading"
        dataTest="continueUploadingButton"
      />
    </>
  );

  return (
    <Modal
      className={ styles.cancelUploadModal }
      onCloseClicked={ hideCanceledModal }
      isVisible
      title="Are you sure?"
      closeOnEsc={ false }
      modalBodyClassName={ styles.modalBody }
      footer={ footer }
      dataTest="cancelUploadModal"
    >
      <div className={ styles.content }>
        Canceling this upload will result in no files being uploaded. Are you sure you want to cancel?
      </div>
    </Modal>
  );
};

export default CancelUploadModal;
