import React from 'react';
import { Tooltip } from '@dealroadshow/uikit';
import { THREAD_Z_INDEX } from '@/dataroom/ui/common/Questions/QuestionsExplorer/QuestionThread/contants';

const ThreadTooltip = (props) => (
  <Tooltip
    { ...props }
    zIndex={ THREAD_Z_INDEX + 1 }
  >
    { props.children }
  </Tooltip>
);

export default ThreadTooltip;
